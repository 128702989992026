import {Routes, Route, Navigate} from 'react-router-dom'

import Home from '../pages/Home';

import About from '../pages/About';
import Careers from '../pages/Careers';
import Investment from '../pages/Investment';
import Faq from '../pages/Faq';
import Contact from '../pages/Contact';
import Ebook from '../pages/Ebook';
import Page1 from '../pages/page1';
import Page2 from '../pages/page2';
import Blog from '../pages/Blog';
import Blog1 from '../pages/Bolg1';
import Resources from '../pages/Resources';
import Article from '../pages/Article.jsx';
import Blog3 from '../pages/Blog3';
import Ebook2 from '../pages/Ebook2';
import Blog4 from '../pages/Blog4';
import Blog5 from '../pages/Blog5';
import Blog6 from '../pages/Blog6';
import Blog7 from '../pages/Blog7';
import Blog8 from '../pages/Blog8';
import Blog9 from '../pages/Blog9';
import Blog10 from '../pages/Blog10.jsx';
import Blog11 from '../pages/Blog11.jsx';
import Blog12 from '../pages/Blog12.jsx';
import Blog13 from '../pages/Blog13.jsx';
import Blog14 from '../pages/Blog14.jsx';
import Blog15 from '../pages/Blog15.jsx';
import Blog16 from '../pages/Blog16.jsx';
const Routers =()=> {
  return (
    <Routes>x 
      <Route path='/' element={<Home/>} />
      <Route path='/Home' element={<Home/>} />
      <Route path='/About-Us' element={<About/>} />
      <Route path='/Careers' element={<Careers/>} />
      <Route path='/Investment-Strategy' element={<Investment/>} />
      <Route path='/Faq' element={<Faq/>} />
      <Route path='/Contact-Us' element={<Contact/>} />
      <Route path='/ebook' element={<Ebook/>} />
      <Route path='/blogs' element={<Blog/>} />
      <Route path='/Mastering-Investment-Strategies-and-Styles' element={<Blog1/>} />
      <Route path='/Navigatingthe-Market-Transition-From-Bear-to-Bull' element={<Article/>} />
      <Route path='/Landing-Page-For-Meeting' element={<Page1/>} />
      <Route path='/Landing-Page-For-Meeting2' element={<Page2/>} />
      <Route path='/Fund-Information' element={<Resources/>} />
      <Route path='/Navigating-the-Uncertainties' element={<Blog3/>} />
      <Route path='/Long-term-Growth-Strategies' element={<Blog4/>} />
      <Route path='/A-Strategic-Shift-Towards-Accumulation-Mode' element={<Blog5/>} />
      <Route path='/January-2024-high-tech-success-insights' element={<Blog6/>} />
      <Route path="/today's-stock-market-analysis" element={<Blog7/>} />
      <Route path="/Navigating-Prosperity" element={<Blog8/>} />
      <Route path="/Navigating-Market-Dynamics" element={<Blog9/>} />
      <Route path="/Bitcoin-Soars-to-New-Heights" element={<Blog10/>} />
      <Route path="/Mastering-Market-Volatility" element={<Blog11/>} />
      
      <Route path="/Investing-in-Tomorrow" element={<Blog12/>} />
      <Route path="/Navigating-Market-Volatility" element={<Blog13/>} />
      <Route path="/Navigatin-the-Winds-of-Change" element={<Blog14/>} />
      <Route path="/A-Key-Strategy-in-Today's-Shariah-Compliant Market" element={<Blog15/>} />
      <Route path="/The-Importance-of-Value-Investing" element={<Blog16/>} />    
      <Route path='/Ebook-download' element={<Ebook2/>} />

</Routes>
  )
};
export default Routers;
























